@import "variables";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  mat-card {
    padding: 2 * $base-padding;
    box-sizing: border-box;
  }
}

.avelon-theme {
  --mat-card-subtitle-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.54);
}

.avelon-theme.avelon-dark-theme {
  --mat-card-subtitle-text-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.7);
}
