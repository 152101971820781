.avelon-theme,
.avelon-theme.avelon-dark-theme {
  --mat-sidenav-container-divider-color: rgba(var(--on-primary-rgb), 0.12);

  .mat-drawer-content {
    background-color: var(--view-background);
  }
}

.avelon-theme:not(.avelon-dark-theme) {
  --mat-sidenav-container-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.87);
}
