@import "variables";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  mat-spinner.mat-mdc-progress-spinner {
    --mdc-circular-progress-active-indicator-color: var(--text);

    .mdc-circular-progress__spinner-layer {
      opacity: 0.2;
    }
  }
}
