@import "variables";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  .mat-divider {
    &.mat-divider-horizontal {
      margin: $base-padding * 0.5 0;
    }

    &.mat-divider-vertical {
      margin: 0 $base-padding * 0.5;
    }
  }
}