@use "@angular/material" as mat;

@include mat.core();

$material-theme: mat.define-theme(
    (
      color: (
        theme-type: light,
        use-system-variables: true,
        system-variables-prefix: mat-theme-light
      ),
      typography: (use-system-variables: true),
      density: (scale: -1)
    )
);

$material-dark-theme: mat.define-theme(
    (
      color: (
        theme-type: dark,
        use-system-variables: true,
        system-variables-prefix: mat-theme-dark
      )
    )
);

.avelon-theme {
  // System variables used by Material to determine colors
  --mat-theme-light-primary: #eaeced;
  --mat-theme-light-primary-container: #ffffff;
  --mat-theme-light-secondary-container: rgba(0, 0, 0, 0.04);
  --mat-theme-light-background: #fafafa;
  --mat-theme-light-surface: #ffffff;
  --mat-theme-light-surface-container: #ffffff;
  --mat-theme-light-surface-container-low: #ffffff;
  --mat-theme-light-surface-container-high: #ffffff;
  --mat-theme-light-surface-variant: #eaeced;
  --mat-theme-light-inverse-surface: rgba(0, 0, 0, 0.04);
  --mat-theme-light-outline: rgba(0, 0, 0, 0.12);
  --mat-theme-light-outline-variant: rgba(0, 0, 0, 0.12);
  --mat-theme-light-shadow: #000000;
  --mat-theme-light-error: #fd625e;
  --mat-theme-light-on-primary: #000000;
  --mat-theme-light-on-primary-container: #000000;
  --mat-theme-light-on-secondary-container: #eaeced;
  --mat-theme-light-on-background: rgba(0, 0, 0, 0.87);
  --mat-theme-light-on-surface: #000000;
  --mat-theme-light-on-surface-variant: #000000;
  --mat-theme-light-inverse-on-surface: #ffffff;
  --mat-theme-light-on-error: #000000;
  --mat-theme-light-on-error-container: #fd625e;

  --mat-theme-dark-primary: #333333;
  --mat-theme-dark-primary-container: #424242;
  --mat-theme-dark-secondary-container: #212121;
  --mat-theme-dark-background: #303030;
  --mat-theme-dark-surface: #424242;
  --mat-theme-dark-surface-container: #424242;
  --mat-theme-dark-surface-container-low: #424242;
  --mat-theme-dark-surface-container-high: #424242;
  --mat-theme-dark-surface-variant: #333333;
  --mat-theme-dark-inverse-surface: #616161;
  --mat-theme-dark-outline: rgba(255, 255, 255, 0.12);
  --mat-theme-dark-outline-variant: rgba(255, 255, 255, 0.12);
  --mat-theme-dark-shadow: #000000;
  --mat-theme-dark-error: #fd625e;
  --mat-theme-dark-on-primary: #ffffff;
  --mat-theme-dark-on-primary-container: #ffffff;
  --mat-theme-dark-on-secondary-container: #333333;
  --mat-theme-dark-on-background: #ffffff;
  --mat-theme-dark-on-surface: #ffffff;
  --mat-theme-dark-on-surface-variant: #ffffff;
  --mat-theme-dark-inverse-on-surface: #ffffff;
  --mat-theme-dark-on-error: #ffffff;
  --mat-theme-dark-on-error-container: #fd625e;

  @include mat.all-component-themes($material-theme);
  // Reset base styles (shapes, sizes, elevations, opacities, slide toggle etc.) to default values
  @include mat.all-component-bases(null);

  &.avelon-dark-theme {
    @include mat.all-component-colors($material-dark-theme);
    color: white;
  }
}
