.avelon-theme,
.avelon-theme.avelon-dark-theme {
  mat-icon.icon-hue {
    color: var(--icon-hue);
  }

  mat-icon.bold {
    font-weight: 700;
  }
}
