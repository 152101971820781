@use "@angular/material" as mat;

@import "variables";
@import "scss/hover";

.map-marker-div-icon {
  height: initial !important;
  width: initial !important;
  white-space: nowrap;
  font-size: 12px;
  font-family: $font-family;
  padding: $base-padding * 0.25 $base-padding;
  background-color: var(--window-background);
  border: 1px solid rgba(0, 0, 0, 0.14);
  border-radius: 2 * $base-size 2 * $base-size 2 * $base-size 0;
  transition: background-color, box-shadow 250ms $default-easing;
  @include mat.elevation(7);
  @include hover;

  .map-marker-custom-div {
    display: grid;
    grid-template-columns: repeat(5, min-content);
    align-items: center;
  }

  .entity-icon {
    color: var(--icon-hue);
    font-size: 2 * $base-size;
    margin: $base-padding 0;
    width: 2 * $base-size;
    height: 2 * $base-size;
    min-width: 2 * $base-size;
    min-height: 2 * $base-size;
  }

  &:hover {
    &::before {
      z-index: -1;
    }

    .entity-icon {
      color: var(--hover-text);
    }
  }

  label {
    cursor: pointer;
    margin: $base-padding $base-padding * 0.5;
    max-width: 120px;
  }

  mat-icon:not(.entity-icon) {
    font-size: 2 * $base-size;
    height: 2 * $base-size;
    margin: $base-padding * 0.5 0;
    min-height: 2 * $base-size;
  }

  label,
  mat-icon:not(.entity-icon) {
    display: none;
  }
}

.large-icons .map-marker-div-icon {
  label,
  mat-icon:not(.entity-icon) {
    display: block;
  }
}

.marker-cluster-small,
.marker-cluster-medium,
.marker-cluster-large {
  background-color: var(--tertiary-40);
  color: var(--on-tertiary);
  @include mat.elevation(7);

  div {
    background-color: var(--tertiary-20);
    color: var(--on-tertiary);
  }
}

.map-marker-geofencing mat-icon {
  background: var(--blue);
  border-radius: 50%;
  color: var(--on-blue);
  font-size: 18px;
  margin: -11px;
  padding: $base-padding;
}
