// Define CSS custom properties for default color palette that are overridden by branding.
.avelon-theme {
  // Main Avelon theme palette for light and dark mode.
  --avelon-theme-light-primary-5: #f3f4f4;
  --avelon-theme-light-primary-10: #eff0f1; // table headers hovered
  --avelon-theme-light-primary-20: #eaeced; // table headers
  --avelon-theme-light-primary-30: #e7e7e9; // secondary toolbar selected
  --avelon-theme-light-primary-40: #dedfe1; // secondary toolbar hovered
  --avelon-theme-light-primary-50: #d3d4d7; // secondary toolbar
  --avelon-theme-light-primary-60: #c7c9cd; // primary toolbar selected
  --avelon-theme-light-primary-70: #bcbec3; // primary toolbar hovered
  --avelon-theme-light-primary-80: #b0b3b9; // primary toolbar
  --avelon-theme-light-primary-90: #616a73; // topbar on dashboards
  --avelon-theme-light-tertiary-10: #a5a8af;
  --avelon-theme-light-tertiary-20: #616a73;
  --avelon-theme-light-tertiary-40: #1f2837;
  --avelon-theme-light-on-primary: #000000;
  --avelon-theme-light-on-primary-90: #ffffff;
  --avelon-theme-light-on-tertiary: #ffffff;
  --avelon-theme-light-on-primary-rgb: 0, 0, 0;

  --avelon-theme-dark-primary-5: #222222;
  --avelon-theme-dark-primary-10: #2b2b2b;
  --avelon-theme-dark-primary-20: #333333;
  --avelon-theme-dark-primary-30: #3d3d3d;
  --avelon-theme-dark-primary-40: #454545;
  --avelon-theme-dark-primary-50: #4e4e4e;
  --avelon-theme-dark-primary-60: #575757;
  --avelon-theme-dark-primary-70: #5f5f5f;
  --avelon-theme-dark-primary-80: #686868;
  --avelon-theme-dark-primary-90: #7a7a7a;
  --avelon-theme-dark-tertiary-10: #6b6b6b;
  --avelon-theme-dark-tertiary-20: #7e7e7e;
  --avelon-theme-dark-tertiary-40: #929292;
  --avelon-theme-dark-on-primary: #ffffff;
  --avelon-theme-dark-on-tertiary: #ffffff;
  --avelon-theme-dark-on-primary-rgb: 255, 255, 255;

  // Accent colors
  --avelon-theme-light-blue: #039be5;
  --avelon-theme-light-green: #01b8aa;
  --avelon-theme-light-yellow: #f2cf00;
  --avelon-theme-light-orange: #ff922e;
  --avelon-theme-light-red: #fd625e;
  --avelon-theme-light-on-blue: #ffffff;
  --avelon-theme-light-on-green: #ffffff;
  --avelon-theme-light-on-yellow: #000000;
  --avelon-theme-light-on-orange: #000000;
  --avelon-theme-light-on-red: #ffffff;

  --avelon-theme-dark-blue: #039be5;
  --avelon-theme-dark-green: #01b8aa;
  --avelon-theme-dark-yellow: #f2cf00;
  --avelon-theme-dark-orange: #ff922e;
  --avelon-theme-dark-red: #fd625e;
  --avelon-theme-dark-on-blue: #ffffff;
  --avelon-theme-dark-on-green: #ffffff;
  --avelon-theme-dark-on-yellow: #000000;
  --avelon-theme-dark-on-orange: #ffffff;
  --avelon-theme-dark-on-red: #ffffff;

  // Accent colors for slightly tinted background (in table rows etc.)
  --avelon-theme-light-tint-blue: rgba(3, 155, 229, 0.2);
  --avelon-theme-light-tint-green: rgba(1, 184, 170, 0.2);
  --avelon-theme-light-tint-yellow: rgba(242, 207, 0, 0.2);
  --avelon-theme-light-tint-orange: rgba(255, 146, 46, 0.2);
  --avelon-theme-light-tint-red: rgba(253, 98, 94, 0.2);
  --avelon-theme-light-on-tint-blue: var(--avelon-theme-light-text);
  --avelon-theme-light-on-tint-green: var(--avelon-theme-light-text);
  --avelon-theme-light-on-tint-yellow: var(--avelon-theme-light-text);
  --avelon-theme-light-on-tint-orange: var(--avelon-theme-light-text);
  --avelon-theme-light-on-tint-red: var(--avelon-theme-light-text);

  --avelon-theme-dark-tint-blue: rgba(3, 155, 229, 0.2);
  --avelon-theme-dark-tint-green: rgba(1, 184, 170, 0.2);
  --avelon-theme-dark-tint-yellow: rgba(242, 207, 0, 0.2);
  --avelon-theme-dark-tint-orange: rgba(255, 146, 46, 0.2);
  --avelon-theme-dark-tint-red: rgba(253, 98, 94, 0.2);
  --avelon-theme-dark-on-tint-blue: var(--avelon-theme-dark-text);
  --avelon-theme-dark-on-tint-green: var(--avelon-theme-dark-text);
  --avelon-theme-dark-on-tint-yellow: var(--avelon-theme-dark-text);
  --avelon-theme-dark-on-tint-orange: var(--avelon-theme-dark-text);
  --avelon-theme-dark-on-tint-red: var(--avelon-theme-dark-text);

  // Message box colors
  --avelon-theme-light-message-blue: rgba(3, 155, 229, 0.3);
  --avelon-theme-light-message-green: rgba(1, 184, 170, 0.3);
  --avelon-theme-light-message-yellow: rgba(242, 207, 0, 0.3);
  --avelon-theme-light-message-orange: rgba(255, 146, 46, 0.3);
  --avelon-theme-light-message-red: rgba(253, 98, 94, 0.3);
  --avelon-theme-light-on-message-blue: var(--avelon-theme-light-text);
  --avelon-theme-light-on-message-green: var(--avelon-theme-light-text);
  --avelon-theme-light-on-message-yellow: var(--avelon-theme-light-text);
  --avelon-theme-light-on-message-orange: var(--avelon-theme-light-text);
  --avelon-theme-light-on-message-red: var(--avelon-theme-light-text);

  --avelon-theme-dark-message-blue: rgba(3, 155, 229, 0.3);
  --avelon-theme-dark-message-green: rgba(1, 184, 170, 0.3);
  --avelon-theme-dark-message-yellow: rgba(242, 207, 0, 0.3);
  --avelon-theme-dark-message-orange: rgba(255, 146, 46, 0.3);
  --avelon-theme-dark-message-red: rgba(253, 98, 94, 0.3);
  --avelon-theme-dark-on-message-blue: var(--avelon-theme-dark-text);
  --avelon-theme-dark-on-message-green: var(--avelon-theme-dark-text);
  --avelon-theme-dark-on-message-yellow: var(--avelon-theme-dark-text);
  --avelon-theme-dark-on-message-orange: var(--avelon-theme-dark-text);
  --avelon-theme-dark-on-message-red: var(--avelon-theme-dark-text);

  // Background colors
  --avelon-theme-light-view-background: var(--avelon-theme-light-primary-5); // background behind cards or on inactive areas
  --avelon-theme-dark-view-background: var(--avelon-theme-dark-primary-20);

  --avelon-theme-light-window-background: white; // lists, trees
  --avelon-theme-dark-window-background: var(--avelon-theme-dark-primary-10);

  --avelon-theme-light-material-background: white; // windows, menus, dropdowns, cards
  --avelon-theme-dark-material-background: #424242;

  --avelon-theme-light-material-input-background: rgba(0, 0, 0, 0.04);
  --avelon-theme-dark-material-input-background: rgba(255, 255, 255, 0.1);

  --avelon-theme-light-drag-indicator-background: var(--avelon-theme-light-primary-60);
  --avelon-theme-dark-drag-indicator-background: var(--avelon-theme-dark-primary-60);

  // Text colors
  --avelon-theme-light-text: rgba(0, 0, 0, 0.8);
  --avelon-theme-dark-text: white;

  --avelon-theme-light-link: var(--avelon-theme-light-blue);
  --avelon-theme-dark-link: var(--avelon-theme-dark-blue);

  --avelon-theme-light-text-disabled: rgba(0, 0, 0, 0.38);
  --avelon-theme-dark-text-disabled: rgba(255, 255, 255, 0.38);

  --avelon-theme-light-icon-hue: rgba(0, 0, 0, 0.8);
  --avelon-theme-dark-icon-hue: var(--avelon-theme-dark-text);

  --avelon-theme-light-icon-hue-disabled: rgba(0, 0, 0, 0.26);
  --avelon-theme-dark-icon-hue-disabled: rgba(255, 255, 255, 0.26);

  --avelon-theme-light-hover: rgba(102, 108, 120, 0.1);
  --avelon-theme-dark-hover: rgba(255, 255, 255, 0.1);

  --avelon-theme-light-hover-text: var(--avelon-theme-light-on-primary);
  --avelon-theme-dark-hover-text: var(--avelon-theme-dark-on-primary);

  --avelon-theme-light-selected: var(--avelon-theme-light-primary-30);
  --avelon-theme-dark-selected: var(--avelon-theme-dark-primary-50);

  --avelon-theme-light-selected-text: var(--avelon-theme-light-on-primary);
  --avelon-theme-dark-selected-text: var(--avelon-theme-dark-on-primary);

  --avelon-theme-light-selected-shape-fill: rgba(160, 162, 170, 0.2);
  --avelon-theme-dark-selected-shape-fill: rgba(160, 162, 170, 0.2);

  --avelon-theme-light-selected-shape-stroke: #a0a2aa;
  --avelon-theme-dark-selected-shape-stroke: #a0a2aa;

  --avelon-theme-light-label-text: rgba(0, 0, 0, 0.7);
  --avelon-theme-dark-label-text: rgba(255, 255, 255, 0.7);

  --avelon-theme-light-box-border: rgba(0, 0, 0, 0.2);
  --avelon-theme-dark-box-border: rgba(255, 255, 255, 0.2);

  --avelon-theme-light-box-transparency: #{rgba(rgba(0, 0, 0, 0.2), 0)};
  --avelon-theme-dark-box-transparency: #{rgba(rgba(255, 255, 255, 0.2), 0)};

  // Module toolbar colors
  --avelon-theme-main-navigation-tint-left: rgba(0, 0, 0, 0.15);
  --avelon-theme-main-navigation-tint-text-left: inherit;
  --avelon-theme-main-navigation-tint-right: rgba(0, 0, 0, 0.15);
  --avelon-theme-main-navigation-tint-text-right: inherit;
  --avelon-theme-light-topbar: var(--avelon-theme-light-primary-90);
  --avelon-theme-dark-topbar: var(--avelon-theme-dark-primary-90);
  --avelon-theme-light-topbar-text: var(--avelon-theme-light-on-primary-90);
  --avelon-theme-dark-topbar-text: var(--avelon-theme-dark-on-primary);
  --avelon-theme-users-module: #447abe;
  --avelon-theme-users-module-text: var(--avelon-theme-dark-text);
  --avelon-theme-devices-module: #01b8aa;
  --avelon-theme-devices-module-text: var(--avelon-theme-dark-text);
  --avelon-theme-documents-module: #7550a9;
  --avelon-theme-documents-module-text: var(--avelon-theme-dark-text);
  --avelon-theme-shop-module: #ffc828;
  --avelon-theme-shop-module-text: var(--avelon-theme-dark-text);
  --avelon-theme-settings-module: #ffa837;
  --avelon-theme-settings-module-text: var(--avelon-theme-dark-text);
  --avelon-theme-navigation-module: #5db3ec;
  --avelon-theme-navigation-module-text: var(--avelon-theme-dark-text);

  --tooltip-background: rgba(97, 97, 97, 0.9);
}
