@mixin mat-slide-toggle(
  $selected-handle-color,
  $selected-track-color,
  $unselected-handle-color,
  $unselected-track-color,
  $selected-icon-color,
  $unselected-icon-color,
  $text-color
) {
  @if $selected-handle-color != null {
    --mdc-switch-selected-handle-color: #{$selected-handle-color} !important;
    --mdc-switch-selected-focus-handle-color: #{$selected-handle-color} !important;
    --mdc-switch-selected-hover-handle-color: #{$selected-handle-color} !important;
    --mdc-switch-selected-pressed-handle-color: #{$selected-handle-color} !important;
  }

  @if $selected-track-color != null {
    --mdc-switch-selected-track-color: #{$selected-track-color} !important;
    --mdc-switch-selected-focus-track-color: #{$selected-track-color} !important;
    --mdc-switch-selected-hover-track-color: #{$selected-track-color} !important;
    --mdc-switch-selected-pressed-track-color: #{$selected-track-color} !important;
  }

  @if $unselected-handle-color != null {
    --mdc-switch-unselected-handle-color: #{$unselected-handle-color} !important;
    --mdc-switch-unselected-focus-handle-color: #{$unselected-handle-color} !important;
    --mdc-switch-unselected-hover-handle-color: #{$unselected-handle-color} !important;
    --mdc-switch-unselected-pressed-handle-color: #{$unselected-handle-color} !important;
  }

  @if $unselected-track-color != null {
    --mdc-switch-unselected-track-color: #{$unselected-track-color} !important;
    --mdc-switch-unselected-focus-track-color: #{$unselected-track-color} !important;
    --mdc-switch-unselected-hover-track-color: #{$unselected-track-color} !important;
    --mdc-switch-unselected-pressed-track-color: #{$unselected-track-color} !important;
  }

  @if $text-color != null {
    --mdc-theme-text-primary-on-background: #{$text-color} !important;
  }
  @if $selected-icon-color != null {
    --mdc-switch-selected-icon-color: #{$selected-icon-color} !important;
  }
  @if $unselected-icon-color != null {
    --mdc-switch-unselected-icon-color: #{$unselected-icon-color} !important;
  }
}

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  --mdc-switch-handle-elevation-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.avelon-theme:not(.avelon-dark-theme) {
  --light-switch-disabled: #424242;

  --mat-switch-label-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.87);
  --mdc-switch-handle-surface-color: var(--avelon-theme-light-on-tertiary);
  --mdc-switch-selected-hover-state-layer-color: var(--avelon-theme-light-primary-60);
  --mdc-switch-selected-focus-state-layer-color: var(--avelon-theme-light-primary-60);
  --mdc-switch-selected-pressed-state-layer-color: var(--avelon-theme-light-primary-60);
  --mdc-switch-disabled-label-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.38);
  --mdc-switch-disabled-selected-track-color: var(--light-switch-disabled);
  --mdc-switch-disabled-selected-handle-color: var(--light-switch-disabled);
  --mdc-switch-disabled-selected-icon-color: var(--avelon-theme-light-on-primary-90);
  --mdc-switch-disabled-unselected-track-color: var(--light-switch-disabled);
  --mdc-switch-disabled-unselected-handle-color: var(--light-switch-disabled);
  --mdc-switch-disabled-unselected-icon-color: var(--avelon-theme-light-on-primary-90);

  mat-slide-toggle.mat-mdc-slide-toggle {
    @include mat-slide-toggle(
      $selected-handle-color: var(--primary-90),
      $selected-track-color: var(--primary-40),
      $unselected-handle-color: var(--primary-50),
      $unselected-track-color: var(--primary-10),
      $selected-icon-color: #fff,
      $unselected-icon-color: #fff,
      $text-color: var(--text)
    );
  }

  mat-toolbar mat-slide-toggle.mat-mdc-slide-toggle {
    @include mat-slide-toggle(
      $selected-handle-color: var(--primary-90),
      $selected-track-color: var(--primary-80),
      $unselected-handle-color: var(--primary-40),
      $unselected-track-color: var(--primary-10),
      $selected-icon-color: var(--primary-20),
      $unselected-icon-color: var(--primary-90),
      $text-color: var(--on-primary)
    );
  }
}

.avelon-theme.avelon-dark-theme {
  --mdc-switch-handle-surface-color: var(--avelon-theme-dark-on-primary);
  --mdc-switch-selected-hover-state-layer-color: var(--avelon-theme-dark-primary-30);
  --mdc-switch-selected-focus-state-layer-color: var(--avelon-theme-dark-primary-30);
  --mdc-switch-selected-pressed-state-layer-color: var(--avelon-theme-dark-primary-30);
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-selected-handle-color: #000000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-label-text-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);

  mat-toolbar mat-slide-toggle.mat-mdc-slide-toggle,
  mat-slide-toggle.mat-mdc-slide-toggle {
    @include mat-slide-toggle(
      $selected-handle-color: var(--on-primary),
      $selected-track-color: var(--primary-90),
      $unselected-handle-color: var(--primary-40),
      $unselected-track-color: var(--primary-20),
      $selected-icon-color: var(--primary-60),
      $unselected-icon-color: var(--primary-90),
      $text-color: var(--on-primary)
    );
  }
}
