@import "variables";

input[type="time"]::-webkit-calendar-picker-indicator {
  background: none;
  color: transparent;
  cursor: pointer;
  z-index: 1;
  display: block;
}

input[type="time"]::before {
  background: none;
  color: var(--icon-hue);
  display: inline-block;
  font-family: "Material Symbols" !important;
  font-size: 20px;
  height: 20px;
  position: absolute;
  right: 0;
  vertical-align: middle;
  width: 20px;
}

input[type="time"]::before {
  content: "schedule";
}

input:focus-visible {
  outline: 0;
}

input::placeholder {
  color: var(--text-disabled);
}

input {
  -webkit-tap-highlight-color: transparent; // Remove tap color from Mobile Safari
}
