@import "variables";

.actions {
  color: var(--on-primary);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;

  .mat-mdc-button,
  .mat-mdc-icon-button,
  .mat-mdc-outlined-button {
    border-radius: 0;

    &[disabled],
    &[disabled][disabled] {
      color: var(--text-disabled);

      mat-icon {
        color: var(--icon-hue-disabled);
      }
    }

    &.mat-accent:not([disabled]) {
      background-color: var(--save);
      color: var(--on-save);
    }
  }
}
