.avelon-theme,
.avelon-theme.avelon-dark-theme {
  --mat-slider-focus-state-layer-color: transparent;
  --mdc-slider-label-container-color: var(--on-primary);
  --mdc-slider-with-tick-marks-inactive-container-color: var(--primary-20);
}

.avelon-theme.avelon-dark-theme {
  --mdc-slider-handle-color: var(--on-primary);
  --mdc-slider-focus-handle-color: var(--on-primary);
  --mdc-slider-hover-handle-color: var(--on-primary);
  --mdc-slider-active-track-color: var(--primary-90);
  --mdc-slider-inactive-track-color: var(--primary-90);
}
