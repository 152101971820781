@import "variables";

svg.alcedo-svg-schematic,
svg.alcedo-svg-schematic.widget-theme-default,
svg.alcedo-svg-chart,
svg.alcedo-svg-chart.widget-theme-default,
gridster gridster-item,
gridster gridster-item.widget-theme-default,
.widget-theme-default {
  background-color: var(--light-widget-hue);
}

svg.alcedo-svg-chart.widget-theme-default,
svg.alcedo-svg-chart.widget-theme-linearGradient,
svg.alcedo-svg-chart.widget-theme-radialGradient {
  rect.background {
    fill: var(--light-widget-hue);
  }
}

svg.alcedo-svg-schematic.widget-theme-semiTransparent,
svg.alcedo-svg-chart.widget-theme-semiTransparent,
gridster gridster-item.widget-theme-semiTransparent,
.widget-theme-semiTransparent {
  background-color: var(--light-widget-hue-semi-transparent);
}

svg.alcedo-svg-chart.widget-theme-semiTransparent rect.background {
  fill: var(--light-widget-hue-semi-transparent);
}

svg.alcedo-svg-schematic.widget-theme-transparent,
svg.alcedo-svg-chart.widget-theme-transparent,
gridster gridster-item.widget-theme-transparent,
.widget-theme-transparent {
  background-color: transparent;
  background-image: none;
}

svg.alcedo-svg-chart.widget-theme-transparent rect.background {
  fill: transparent;
}

svg.alcedo-svg-schematic.widget-theme-linearGradient,
svg.alcedo-svg-chart.widget-theme-linearGradient,
gridster gridster-item.widget-theme-linearGradient,
.widget-theme-linearGradient {
  background-image: linear-gradient(to bottom right, var(--light-widget-hue), var(--medium-widget-hue));
}

svg.alcedo-svg-schematic.widget-theme-radialGradient,
svg.alcedo-svg-chart.widget-theme-radialGradient,
gridster gridster-item.widget-theme-radialGradient,
.widget-theme-radialGradient {
  background-image: radial-gradient(var(--light-widget-hue) 10%, var(--medium-widget-hue) 65%);
}

mat-dialog-container svg.alcedo-svg-schematic,
mat-dialog-container svg.alcedo-svg-chart {
  &,
  &.widget-theme-default,
  &.widget-theme-linearGradient,
  &.widget-theme-radialGradient,
  &.widget-theme-semiTransparent,
  &.widget-theme-transparent {
    // Disable background style when schematic is displayed in dialog.
    background-color: transparent !important;
    background-image: none !important;
  }
}
