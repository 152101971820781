@keyframes avelon-continuous-rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.continuous-rotate {
  animation: avelon-continuous-rotate 1.8s infinite;
  animation-timing-function: linear;
}

@keyframes alcedo-flash {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1.0;
  }
  100% {
    opacity: 0;
  }
}

.flash {
  animation: alcedo-flash 1.8s infinite;
  animation-timing-function: linear;
}

.hourglass-animation {
  animation: hourglass-spin 3s infinite linear;
}

@keyframes hourglass-spin {
  0% {
    transform: rotate(0deg);
  }
  40% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  90% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
