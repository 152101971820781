@import "root-only.mixin";
.root-only:not([class*="mat-mdc-"]) {
  position: relative;
  &::before {
    @include root-only();
    display: block;
    content: " ";
    border-radius: inherit;
    inset: 0;
    margin: 0;
    position: absolute;
  }

  & > * {
    // Needed to position child elements on top of the ::before pseudoelement (since the pseudoelement is in a different stacking context due to its absolute positioning).
    position: relative;
  }
}
