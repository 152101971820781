@import "variables";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  .mat-toolbar {
    height: $default-mat-toolbar-height;
    background-color: var(--primary-80);
    color: var(--on-primary);
    font-size: $font-size;
    overflow: hidden;
    padding: 0;
    position: relative;
    transition: all 0.3s $default-easing;

    &::after {
      position: absolute;
      content: " ";
      height: 0;
      background-color: transparent;
      z-index: 1;
      bottom: 0;
      left: 0;
      right: 0;
      transition: all 0.3s $default-easing;
    }

    &.edit-mode {
      height: calc($default-mat-toolbar-height + var(--edit-mode-indicator-size));
      padding-bottom: var(--edit-mode-indicator-size);
    }

    &.edit-mode::after {
      background-color: var(--edit-mode);
      height: var(--edit-mode-indicator-size);
    }

    &.edit-mode .quit-edit {
      background-color: var(--edit-mode);
      color: var(--on-edit-mode);
    }

    &.mat-primary {
      background-color: var(--primary-90);
      color: var(--on-primary-90);
    }

    &.secondary-toolbar {
      background-color: var(--primary-50);
      color: var(--on-primary);
    }

    .mat-mdc-button,
    .mat-mdc-icon-button {
      border-radius: 0;
      height: $base-height;
    }

    .mat-mdc-icon-button[disabled] mat-icon {
      color: var(--icon-hue-disabled);
    }

    mat-radio-button {
      .mdc-radio__outer-circle,
      .mdc-radio__inner-circle {
        border-color: var(--on-primary) !important;
      }

      label {
        color: var(--on-primary) !important;
      }
    }
  }
}

.avelon-theme:not(.avelon-dark-theme) {
  --mat-toolbar-container-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.87);
}
