@mixin hover {
  & {
    overflow: hidden;
    position: relative;

    &:hover {
      color: var(--hover-text);

      &::before {
        display: block;
        content: " ";
        inset: 0;
        position: absolute;
        background-color: var(--hover);
      }

      mat-icon {
        color: var(--hover-text);
      }

      & > * {
        // Needed to position child elements on top of the ::before pseudoelement (since the pseudoelement is in a different stacking context due to its absolute positioning).
        position: relative;
      }
    }
  }
}
