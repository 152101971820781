#outdated {
  background-color: #ff8522;
  box-sizing: border-box;
  color: white;
  left: 0;
  margin: 25vh 0;
  overflow: hidden;
  padding: 24px;
  position: absolute;
  text-align: center;
  width: 100%;
  z-index: 1500;

  h6 {
    font-size: 2em;
    line-height: 1em;
    margin: 24px;
  }

  p {
    font-size: 1em;
    line-height: 1em;
    margin: 0 24px 24px;
  }

  a {
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    margin: 8px;
    padding: 12px 24px;
    position: relative;
    text-decoration: none;

    &.update {
      background-color: #6ec1e4;
      color: white;
    }

    &.continue {
      background-color: #ffffff;
      color: black;
    }
  }
}
