@use "sass:math";
@import "variables";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  --mat-badge-small-size-text-size: none;
  --mat-badge-text-color: white;
  --mat-badge-line-height: 22px;
  --mat-badge-small-size-line-height: 16px;

  .mat-badge-content {
    text-overflow: clip;
  }

  .auto-width .mat-badge-content {
    border-radius: $base-size;
    left: 0;
    min-width: 2 * $base-size;
    overflow: visible;
    padding-left: math.div($base-padding, 2);
    padding-right: math.div($base-padding, 2);
    position: relative;
    width: auto;
  }
}
