.avelon-theme,
.avelon-theme.avelon-dark-theme {
  --mat-datepicker-toggle-icon-color: var(--icon-hue);
  --mat-datepicker-toggle-active-state-icon-color: var(--icon-hue);
  --mat-datepicker-calendar-header-divider-color: rgba(var(--on-primary-rgb), 0.12);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(var(--on-primary-rgb), 0.24);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: var(-on-primary);
  --mat-datepicker-calendar-date-focus-state-background-color: var(--primary-20);
  --mat-datepicker-calendar-date-hover-state-background-color: var(--primary-20);
}

.avelon-theme:not(.avelon-dark-theme) {
  --mat-datepicker-calendar-header-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.38);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.04);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.26);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.87);
  --mat-datepicker-calendar-container-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.87);
  --mat-datepicker-calendar-period-button-icon-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.54);
  --mat-datepicker-range-input-separator-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.87);
}

.avelon-theme.avelon-dark-theme {
  --mat-datepicker-calendar-header-text-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.7);
  --mat-datepicker-calendar-body-label-text-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.1);
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.3);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.3);
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);
}

.mat-datepicker-toggle {
  svg.mat-datepicker-toggle-default-icon path {
    d: path(
      "M5.385,21C5,21 4.673,20.865 4.404,20.596C4.135,20.327 4,20 4,19.615L4,6.154C4,5.77 4.135,5.443 4.404,5.173C4.673,4.904 5,4.769 5.385,4.769L7.452,4.769L7.452,2.769L8.346,2.769L8.346,4.769L15.731,4.769L15.731,2.769L16.567,2.769L16.567,4.769L18.615,4.769C19,4.769 19.327,4.904 19.596,5.173C19.865,5.443 20,5.77 20,6.154L20,19.615C20,20 19.865,20.327 19.596,20.596C19.327,20.865 19,21 18.615,21L5.385,21ZM4.769,9.731L19.231,9.731L19.231,6.154C19.231,6 19.167,5.859 19.038,5.731C18.91,5.603 18.769,5.538 18.615,5.538L5.385,5.538C5.231,5.538 5.09,5.603 4.962,5.731C4.833,5.859 4.769,6 4.769,6.154L4.769,9.731ZM5.385,20.231L18.615,20.231C18.769,20.231 18.91,20.167 19.038,20.038C19.167,19.91 19.231,19.769 19.231,19.615L19.231,10.5L4.769,10.5L4.769,19.615C4.769,19.769 4.833,19.91 4.962,20.038C5.09,20.167 5.231,20.231 5.385,20.231Z"
    );
  }
}
