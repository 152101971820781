.avelon-theme,
.avelon-theme.avelon-dark-theme {
  mat-radio-button .mdc-form-field label {
    cursor: pointer;
  }
}

.avelon-theme:not(.avelon-dark-theme) {
  --mdc-radio-selected-hover-icon-color: var(--avelon-theme-light-tertiary-10);
  --mdc-radio-selected-focus-icon-color: var(--avelon-theme-light-tertiary-10);
  --mdc-radio-selected-icon-color: var(--avelon-theme-light-tertiary-10);
  --mdc-radio-selected-pressed-icon-color: var(--avelon-theme-light-tertiary-10);
  --mdc-radio-unselected-icon-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.54);
}

.avelon-theme.avelon-dark-theme {
  --mat-radio-disabled-label-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);

  .mdc-radio__outer-circle,
  .mdc-radio__inner-circle {
    border-color: var(--text) !important;
  }
}
