@import "variables";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  .mat-mdc-list-base .mat-mdc-list-item {
    font-size: $font-size;
    height: $base-height !important;
  }

  .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background: var(--selected);
    color: var(--selected-text);

    .mdc-list-item__primary-text {
      color: var(--selected-text);
    }
  }
}

.avelon-theme:not(.avelon-dark-theme) {
  --mdc-list-list-item-label-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.87);
  --mdc-list-list-item-hover-label-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.87);
  --mdc-list-list-item-focus-label-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.38);
}

.avelon-theme.avelon-dark-theme {
  --mdc-list-list-item-supporting-text-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);
}
