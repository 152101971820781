@import "variables";
@import "scss/root-only/root-only.mixin";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  --mat-select-focused-arrow-color: var(--text);
  --mat-select-enabled-arrow-color: rgba(var(--on-primary-rgb), 0.54);
  --mat-select-placeholder-text-color: rgba(var(--on-primary-rgb), 0.6);
  --mat-select-enabled-trigger-text-color: rgba(var(--on-primary-rgb), 0.87);
  --mat-optgroup-label-text-color: var(--text-disabled);

  .mat-primary .mat-mdc-optgroup-label {
    font-size: $font-size;
    min-height: $base-height;
  }

  .mat-primary mat-option.mat-mdc-option {
    color: var(--text);
    font-size: $font-size;
    min-height: $base-height;

    mat-icon,
    i {
      color: var(--icon-hue);
      margin-right: $base-padding;
    }

    &.mdc-list-item--selected:not(.mdc-list-item--disabled) {
      .mdc-list-item__primary-text,
      mat-icon,
      i {
        color: inherit;
      }
    }

    &.root-only {
      @include root-only();
    }
  }

  mat-option.mat-mdc-option .mdc-list-item__primary-text {
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .mat-primary .mat-mdc-option.mat-mdc-option-multiple {
    .mat-pseudo-checkbox-checked,
    .mat-pseudo-checkbox-indeterminate {
      background-color: var(--primary-70);
    }

    .mat-pseudo-checkbox {
      margin-right: $base-padding;

      &::after {
        color: #fff;
      }
    }
  }

  // Need it when a "Select All" mat-checkbox is added to a multi select
  .mat-mdc-select-panel .mdc-form-field > label {
    padding-left: 0;
  }
}

.avelon-theme:not(.avelon-dark-theme) {
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.54);
  --mat-full-pseudo-checkbox-disabled-unselected-icon-color: #b0b0b0;
  --mat-option-label-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.87);
  --mat-option-hover-state-layer-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.04);
  --mat-option-focus-state-layer-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.04);
}

.avelon-theme.avelon-dark-theme {
  --mat-full-pseudo-checkbox-unselected-icon-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.7);
  --mat-option-focus-state-layer-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.08);
  --mat-option-selected-state-layer-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.08);
}

@mixin mat-toolbar-select($background-color, $text-color) {
  background-color: $background-color;
  border-radius: 3px;
  margin: 0 $base-padding * 0.5;

  ::ng-deep {
    .mat-mdc-select-arrow {
      color: $text-color;
      margin-right: $base-padding;
    }

    .mat-mdc-select-trigger {
      height: $base-height - $base-padding;
      padding-left: $base-padding;
    }

    .mat-mdc-select-value,
    span {
      color: $text-color;
    }
  }

  &.mat-mdc-select-disabled ::ng-deep {
    .mat-mdc-select-arrow,
    .mat-mdc-select-value,
    span {
      color: var(--text-disabled);
    }
  }
}
