@import "variables";

.flex {
  flex: 1;
  box-sizing: border-box;
}

.flex-none {
  flex: 0 0 auto;
  box-sizing: border-box;
}

.flex-noshrink {
  flex: 1 0 auto;
  box-sizing: border-box;
}

.flex-grow {
  flex: 1 1 100%;
  box-sizing: border-box;
}

.flex-50 {
  flex: 1 1 100%;
  max-width: 50%;
  max-height: 100%;
  box-sizing: border-box;
}

.layout,
.layout-column,
.layout-row {
  box-sizing: border-box;
  display: flex;
}

@mixin layout-column-gap {
  &.layout-column-gap > *:not(:last-child) {
    margin-bottom: $base-padding;
  }
}

@mixin layout-row-gap {
  &.layout-row-gap > *:not(:last-child) {
    margin-right: $base-gap;
  }
}

@mixin layout-row-gap-large {
  &.layout-row-gap-large > *:not(:last-child) {
    margin-right: 2 * $base-gap;
  }
}

.layout-row {
  flex-direction: row;

  @include layout-row-gap;
  @include layout-row-gap-large;

  &.layout-align-space-between {
    align-items: center;
    justify-content: space-between;
    gap: $base-gap;
  }

  > .flex {
    min-width: 0;
  }

  @for $i from 1 through 20 {
    > .flex-#{$i * 5} {
      flex: 1 1 100%;
      max-width: percentage($i * 0.05);
      max-height: 100%;
      box-sizing: border-box;
    }
  }
}

.layout-column {
  flex-direction: column;

  @include layout-column-gap;

  > .flex {
    min-height: 0;
  }

  > .flex-50 {
    flex: 1 1 100%;
    max-width: 100%;
    max-height: 50%;
    box-sizing: border-box;
  }
}

.layout-fill {
  margin: 0;
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.layout-align-center-start,
.layout-align-end-start,
.layout-align-space-around-start,
.layout-align-space-between-start,
.layout-align-start-start {
  align-items: flex-start;
  align-content: flex-start;
}

.layout-align-center-center,
.layout-align-end-center,
.layout-align-space-around-center,
.layout-align-space-between-center,
.layout-align-start-center {
  align-items: center;
  align-content: center;
  max-width: 100%;

  > * {
    max-width: 100%;
    box-sizing: border-box;
  }
}

.layout-align-center-end,
.layout-align-end-end,
.layout-align-space-around-end,
.layout-align-space-between-end,
.layout-align-start-end {
  align-items: flex-end;
  align-content: flex-end;
}

.layout-align-start,
.layout-align-start-center,
.layout-align-start-end,
.layout-align-start-start,
.layout-align-start-stretch {
  justify-content: flex-start;
}

.layout-align-end,
.layout-align-end-center,
.layout-align-end-end,
.layout-align-end-start,
.layout-align-end-stretch {
  justify-content: flex-end;
}

.layout-align-center,
.layout-align-center-center,
.layout-align-center-end,
.layout-align-center-start,
.layout-align-center-stretch {
  justify-content: center;
}

.layout-wrap {
  flex-wrap: wrap;
}

@media screen and (min-width: 600px) {
  .layout-row-gt-xs {
    flex-direction: row;
  }

  .layout-row,
  .layout-row-gt-xs {
    .flex-50-gt-xs {
      flex: 1 1 100%;
      max-width: 50%;
      max-height: 100%;
      box-sizing: border-box;
    }
  }
}

@media screen and (min-width: 1280px) {
  .layout-gt-md-row {
    flex-direction: row;

    @include layout-row-gap;
    @include layout-row-gap-large;
  }

  .layout-row > .flex-20 {
    flex: 1 1 100%;
    max-width: 20%;
    max-height: 100%;
    box-sizing: border-box;
  }
}

@media (min-width: 960px) {
  .layout-gt-sm-row {
    flex-direction: row;

    @include layout-row-gap;
    @include layout-row-gap-large;
  }
}

@media (min-width: 1920px) {
  .layout-gt-lg-row {
    flex-direction: row;

    @include layout-row-gap;
    @include layout-row-gap-large;
  }
}
