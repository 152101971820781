[class*="alcedo-icon-"].large {
  font-size: 64px;
  margin-top: 0;
  margin-bottom: 0;
  width: 64px;
  height: 64px;
}

[class*="alcedo-icon-cdp-"],
[class*="alcedo-icon-file-"] {
  position: relative;
}

/* Files */

.alcedo-icon-file-unknown::before {
  content: "\e90e";
  color: rgba(0, 0, 0, 0.25);
}

.avelon-dark-theme .alcedo-icon-file-unknown::before {
  color: white;
}

.alcedo-icon-file-document::before {
  content: "\e909";
  color: #4285f4;
}

.alcedo-icon-file-document::after {
  content: "\e90a";
}

.alcedo-icon-file-spreadsheet::before {
  content: "\e909";
  color: #0f9d58;
}

.alcedo-icon-file-spreadsheet::after {
  content: "\e90b";
}

.alcedo-icon-file-presentation::before {
  content: "\e909";
  color: #f4b400;
}

.alcedo-icon-file-presentation::after {
  content: "\e90c";
}

.alcedo-icon-file-pdf::before {
  content: "\e909";
  color: #e11e00;
}

.alcedo-icon-file-pdf::after {
  content: "\e90d";
}

.alcedo-icon-file-image::before {
  content: "\e909";
  color: #db4437;
}

.alcedo-icon-file-image::after {
  content: "\e90f";
}

.alcedo-icon-file-document::after,
.alcedo-icon-file-spreadsheet::after,
.alcedo-icon-file-presentation::after,
.alcedo-icon-file-pdf::after,
.alcedo-icon-file-image::after {
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
}

/* Calculated data points */

.alcedo-icon-cdp-data-point::before {
  content: "\e9a6";
  color: #d1d1d1;
}

.alcedo-icon-cdp-data-point::after {
  content: "\e9a7";
  color: #a5a5a5;
  position: absolute;
  left: 0;
  top: 0;
}

.alcedo-icon-cdp-calculated-data-point::before {
  content: "\e9a6";
  color: #983fd2;
}

.alcedo-icon-cdp-calculated-data-point::after {
  content: "\e9a7";
  color: #602d80;
  position: absolute;
  left: 0;
  top: 0;
}

.alcedo-icon-cdp-data-point-group::before {
  content: "\e9a6";
  color: #d1d1d1;
}

.alcedo-icon-cdp-data-point-group::after {
  content: "\e9a7";
  color: #602d80;
  position: absolute;
  left: 0;
  top: 0;
}

.alcedo-icon-cdp-compound::before {
  color: #01b8aa;
}

.alcedo-icon-cdp-sum::after {
  content: "\e9a9";
}

.alcedo-icon-cdp-subtraction::after {
  content: "\e9aa";
}

.alcedo-icon-cdp-product::after {
  content: "\e9ab";
}

.alcedo-icon-cdp-division::after {
  content: "\e9ac";
}

.alcedo-icon-cdp-function::after {
  content: "\e9ad";
}

.alcedo-icon-cdp-equal::after {
  content: "\e9ae";
}

.alcedo-icon-cdp-not-equal::after {
  content: "\e9af";
}

.alcedo-icon-cdp-smaller::after {
  content: "\e9b0";
}

.alcedo-icon-cdp-smaller-equal::after {
  content: "\e9b1";
}

.alcedo-icon-cdp-greater::after {
  content: "\e9b2";
}

.alcedo-icon-cdp-greater-equal::after {
  content: "\e9b3";
}

.alcedo-icon-cdp-accumulation::after {
  content: "\e9b4";
}

.alcedo-icon-cdp-difference::after {
  content: "\e9b5";
}

.alcedo-icon-cdp-and::after {
  content: "\e9b6";
}

.alcedo-icon-cdp-or::after {
  content: "\e9b7";
}

.alcedo-icon-cdp-not::after {
  content: "\e9b8";
}

.alcedo-icon-cdp-if::after {
  content: "\e9b9";
}

.alcedo-icon-cdp-average::after {
  content: "\e9ba";
}

.alcedo-icon-cdp-minimum::after {
  content: "\e9bb";
}

.alcedo-icon-cdp-maximum::after {
  content: "\e9bc";
}

.alcedo-icon-cdp-quantized-sum::after {
  content: "\e9bd";
}

.alcedo-icon-cdp-periodic-difference::after {
  content: "\e9be";
}

.alcedo-icon-cdp-operating-hours::after {
  content: "\e9bf";
}

.alcedo-icon-cdp-time-shift-dynamic::after {
  content: "\e9c0";
}

.alcedo-icon-cdp-time-shift-fixed-year::after {
  content: "\e9c1";
}

.alcedo-icon-cdp-sum,
.alcedo-icon-cdp-subtraction,
.alcedo-icon-cdp-product,
.alcedo-icon-cdp-division,
.alcedo-icon-cdp-function,
.alcedo-icon-cdp-equal,
.alcedo-icon-cdp-not-equal,
.alcedo-icon-cdp-smaller,
.alcedo-icon-cdp-smaller-equal,
.alcedo-icon-cdp-greater,
.alcedo-icon-cdp-greater-equal,
.alcedo-icon-cdp-accumulation,
.alcedo-icon-cdp-difference,
.alcedo-icon-cdp-and,
.alcedo-icon-cdp-or,
.alcedo-icon-cdp-not,
.alcedo-icon-cdp-if,
.alcedo-icon-cdp-average,
.alcedo-icon-cdp-minimum,
.alcedo-icon-cdp-maximum,
.alcedo-icon-cdp-quantized-sum,
.alcedo-icon-cdp-periodic-difference,
.alcedo-icon-cdp-operating-hours,
.alcedo-icon-cdp-time-shift-dynamic,
.alcedo-icon-cdp-time-shift-fixed-year {
  &::before {
    content: "\e9a6";
    color: #01b8aa;
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    color: #ffffff;
  }
}

/* Schematic */

// The content of each of these must match the one in alcedo-font.scss.

.schematic-flags .flag-icon {
  .alcedo-icon-manual-mode-none::after {
    content: "\e964" !important;
  }

  .alcedo-icon-manual-mode::after {
    content: "\e965" !important;
  }

  .alcedo-icon-manual-mode-filled::after {
    content: "\e911" !important;
  }

  .alcedo-icon-manual-mode-lower::after {
    content: "\e966" !important;
  }

  .alcedo-icon-manual-mode-override::after {
    content: "\e967" !important;
  }

  .alcedo-icon-manual-mode-both::after {
    content: "\e968" !important;
  }

  .alcedo-icon-manual-mode-clear::after {
    content: "\e969" !important;
  }

  .alcedo-icon-ticket-status-open::after {
    content: "\e607" !important;
  }

  .alcedo-icon-ticket-status-open-filled::after {
    content: "\e901" !important;
  }

  .alcedo-icon-ticket-status-warning::after {
    content: "\e98a" !important;
  }

  .alcedo-icon-ticket-status-warning-filled::after {
    content: "\e903" !important;
  }

  .alcedo-icon-ticket-status-acknowledged::after {
    content: "\e992" !important;
  }

  .alcedo-icon-ticket-status-acknowledged-filled::after {
    content: "\e904" !important;
  }

  .alcedo-icon-ticket-status-gone::after {
    content: "\e98b" !important;
  }

  .alcedo-icon-ticket-status-gone-filled::after {
    content: "\e905" !important;
  }

  .alcedo-icon-ticket-status-gone-and-acknowledge::after {
    content: "\e98c" !important;
  }

  .alcedo-icon-ticket-status-gone-and-acknowledge-filled::after {
    content: "\e9c3" !important;
  }

  .alcedo-icon-ticket-status-info::after {
    content: "\e98d" !important;
  }

  .alcedo-icon-ticket-status-closed::after {
    content: "\e98e" !important;
  }

  .alcedo-icon-ticket-status-repeated::after {
    content: "\e98f" !important;
  }

  .alcedo-icon-ticket-status-comment::after {
    content: "\e990" !important;
  }
}
