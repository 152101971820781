@import "variables";
@import "scss/root-only/root-only.mixin";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  --mat-form-field-error-focus-trailing-icon-color: unset;
  --mat-form-field-error-hover-trailing-icon-color: unset;
  --mat-form-field-state-layer-color: rgba(var(--on-primary-rgb), 0.87);
  --mat-form-field-enabled-select-arrow-color: rgba(var(--on-primary-rgb), 0.54);
  --mdc-filled-text-field-label-text-color: rgba(var(--on-primary-rgb), 0.6);
  --mdc-filled-text-field-input-text-color: rgba(var(--on-primary-rgb), 0.87);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(var(--on-primary-rgb), 0.6);
  --mdc-filled-text-field-hover-label-text-color: rgba(var(--on-primary-rgb), 0.6);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(var(--on-primary-rgb), 0.87);
  --mdc-filled-text-field-active-indicator-color: rgba(var(--on-primary-rgb), 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(var(--on-primary-rgb), 0.06);
  --mdc-outlined-text-field-label-text-color: rgba(var(--on-primary-rgb), 0.6);
  --mdc-outlined-text-field-input-text-color: rgba(var(--on-primary-rgb), 0.87);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(var(--on-primary-rgb), 0.6);
  --mdc-outlined-text-field-outline-color: rgba(var(--on-primary-rgb), 0.38);
  --mdc-outlined-text-field-hover-outline-color: rgba(var(--on-primary-rgb), 0.87);
  --mdc-outlined-text-field-disabled-outline-color: rgba(var(--on-primary-rgb), 0.06);

  mat-form-field {
    width: 100%;
    /* We need a minimum because of the mat-select, since the mat-option elements may become very small (e.g. schematic
    details sidebar), since the they have the same width as mat-select. */
    min-width: $mat-form-field-min-width;
  }

  .mat-mdc-form-field {
    .mat-mdc-floating-label {
      overflow: visible;
    }

    .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
      content: "＊";
      display: inline-block;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      text-align: center;
    }

    &:not(.ng-touched):not(.ng-valid):not(.mat-form-field-disabled)
      .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
      color: var(--orange);
      font-weight: bold;
      transform: scale(1.2);
      animation: avelon-continuous-rotate 10s infinite;
      animation-timing-function: linear;
    }
  }

  .mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
    padding-right: $base-padding * 0.5;
  }

  .mat-mdc-form-field.root-only .mat-mdc-text-field-wrapper {
    @include root-only();
  }

  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-infix,
  .mat-mdc-form-field-icon-suffix {
    color: var(--icon-hue);
  }

  .mat-mdc-form-field-text-suffix,
  .mat-mdc-form-field-text-prefix {
    white-space: nowrap;
  }

  .mat-mdc-form-field-icon-prefix {
    padding-left: $base-padding;
  }

  .mat-mdc-form-field.mat-form-field-disabled {
    .mat-mdc-form-field-infix,
    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix,
    .mat-mdc-form-field-text-suffix,
    .mat-mdc-form-field-text-prefix {
      color: var(--text-disabled);

      .mdc-icon-button:not([disabled]) {
        color: var(--icon-hue);
      }
    }
  }

  .mat-mdc-form-field.mat-focused {
    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }

    &:not(.mat-form-field-invalid) mat-label {
      color: var(--label-text);
    }
  }

  mat-form-field:not(.mat-form-field-invalid) .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: var(--label-text);
  }

  .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
    opacity: 0;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-text-field__input,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
    caret-color: var(--text);
  }

  // This is needed for long error messages that overflow over other elements
  .mat-form-field-invalid .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align {
    &::before {
      content: none;
    }

    .mat-mdc-form-field-error-wrapper {
      position: relative;
    }
  }
}

.avelon-theme:not(.avelon-dark-theme) {
  --mat-form-field-select-option-text-color: inherit;
  --mat-form-field-error-trailing-icon-color: unset;
  --mdc-filled-text-field-container-color: #f5f5f5;
  --mdc-filled-text-field-disabled-container-color: var(--mat-theme-light-background);
  --mdc-outlined-text-field-hover-label-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.6);
}

.avelon-theme.avelon-dark-theme {
  --mat-form-field-select-option-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-container-color: #4a4a4a;
  --mdc-filled-text-field-disabled-container-color: #464646;
  --mdc-outlined-text-field-hover-label-text-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.6);

  .mat-mdc-form-field:not(.ng-touched):not(.ng-valid):not(.mat-form-field-disabled)
    .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
    // Adjust for better contrast on dark theme.
    color: var(--yellow);
  }
}

@mixin data-point-mat-input {
  ::ng-deep {
    .alcedo-icon-data-point {
      margin-left: 0;
    }

    alc-data-point-label {
      grid-template-columns: 4 * $base-size auto;

      &.data-point-label-with-link {
        grid-template-columns: 4 * $base-size auto $base-width;
      }
    }

    .mat-mdc-form-field-icon-suffix {
      padding: 0;
      white-space: nowrap;
    }

    alc-data-point-link button {
      top: -$base_padding;
    }
  }
}
