@import "scss/root-only/root-only.mixin";

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  --mat-expansion-header-hover-state-layer-color: rgba(var(--on-primary-rgb), 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(var(--on-primary-rgb), 0.04);
}

.avelon-theme:not(.avelon-dark-theme) {
  --mat-expansion-header-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.87);
  --mat-expansion-header-indicator-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.54);
  --mat-expansion-header-description-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.54);
  --mat-expansion-header-disabled-state-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.26);
  --mat-expansion-container-text-color: rgba(var(--avelon-theme-light-on-primary-rgb), 0.87);
}

.avelon-theme.avelon-dark-theme {
  --mat-expansion-header-indicator-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.7);
  --mat-expansion-header-description-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.7);
  --mat-expansion-header-disabled-state-text-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.3);
}

mat-expansion-panel {
  h3 {
    padding-left: 0;
    position: relative;
    transition: padding-left 0.3s $default-easing;

    &::before {
      display: inline-block;
      font-family: "Material Symbols";
      font-size: $icon-size;
      color: var(--red);
      content: "warning";
      left: 0;
      top: -$base-padding * 0.5;
      opacity: 0;
      position: absolute;
      transform: scale(0);
      transition: opacity 0.3s $default-easing 0.2s;
    }

    // You can use the data-error-icon attribute to set your own icon
    // that appears when class="error" is set on the h3 element of the panel.
    // [class.error]="hasError" data-error-icon="warning"
    &[data-error-icon]::before {
      content: attr(data-error-icon);
    }

    &.error {
      padding-left: $icon-size + $base-padding;

      &::before {
        opacity: 1;
        transition:
          opacity 0.3s $default-easing 0s,
          transform 0.3s $default-easing 0s;
        transform: scale(1);
      }
    }
  }

  &.root-only {
    .mat-expansion-panel-header.mat-expanded:focus,
    .mat-expansion-panel-header.mat-expanded:hover {
      @include root-only();
    }
  }
}

.no-content-padding {
  .mat-expansion-panel-body {
    padding: 0;
  }
}
