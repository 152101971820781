@import "variables";

@mixin hide-form-field-wrapper-and-ripple {
  .mat-mdc-form-field-subscript-wrapper,
  .mdc-text-field--filled:not(.mdc-text-field--disabled) .mdc-line-ripple::before,
  .mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-line-ripple::after,
  .mdc-line-ripple.mdc-line-ripple--active {
    display: none;
  }
}

@mixin hide-label-and-fix-arrow {
  label {
    display: none;
  }

  &.mat-form-field-appearance-fill .mat-mdc-select-arrow-wrapper {
    transform: none;
  }
}

@mixin disable-form-field-min-width {
  min-width: 0;

  .mat-mdc-form-field-infix {
    width: 0;

    mat-select {
      min-width: 0;
    }
  }
}

@mixin center-form-field-input {
  .mat-mdc-text-field-wrapper,
  .mat-mdc-form-field-flex {
    align-items: center;

    .mat-mdc-form-field-infix {
      display: flex;
    }
  }
}

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  mat-form-field {
    &.mat-hide-form-field-wrapper-and-ripple {
      @include hide-form-field-wrapper-and-ripple;
    }

    &.mat-disable-form-field-min-width {
      @include disable-form-field-min-width;
    }

    &:not(:has(.mat-mdc-floating-label)) {
      @include center-form-field-input;
    }

    @each $size in 32, 40 {
      &.mat-#{$size} {
        @include hide-form-field-wrapper-and-ripple;
        @include hide-label-and-fix-arrow;

        height: #{$size}px;

        .mat-mdc-form-field-infix {
          padding: 0 !important;
          min-height: 0;
          line-height: #{$size}px;

          .mat-mdc-select {
            height: #{$size}px;
          }

          input {
            line-height: #{$size * 0.5}px;
          }
        }
      }
    }
  }
}
