@import "variables";

@mixin default-scrollbar($regularColor, $hoverColor) {
  &::-webkit-scrollbar {
    width: 1.5 * $base-size;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #{$regularColor};
    background-clip: padding-box;
    border: 3px solid transparent;
    border-radius: $base-size;

    &:hover {
      background: #{$hoverColor};
      background-clip: padding-box;
    }
  }
}
