@import "variables";
@import "scss/root-only/root-only.mixin";

.avelon-theme:not(.avelon-dark-theme) {
  --mat-filled-button-hover-state-layer-opacity: 0.04;
  --mat-text-button-hover-state-layer-opacity: 0.04;

  .mat-mdc-unelevated-button {
    &.mat-unthemed {
      --mdc-filled-button-container-color: var(--avelon-theme-light-primary-40);
      --mdc-filled-button-label-text-color: var(--avelon-theme-light-on-primary);
    }

    &.mat-unthemed[disabled] {
      --mdc-filled-button-disabled-container-color: rgba(222, 223, 225, 0.5);
      --mdc-filled-button-disabled-label-text-color: var(--avelon-theme-light-text-disabled);
    }

    &.mat-primary {
      --mdc-filled-button-container-color: var(--avelon-theme-light-primary-70);
      --mdc-filled-button-label-text-color: var(--avelon-theme-light-on-primary);
    }

    &.mat-primary[disabled] {
      --mdc-filled-button-disabled-container-color: rgba(188, 190, 195, 0.5);
      --mdc-filled-button-disabled-label-text-color: var(--avelon-theme-light-text-disabled);
    }
  }
}

.avelon-theme.avelon-dark-theme {
  --mat-fab-disabled-state-foreground-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);
  --mdc-text-button-disabled-label-text-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(var(--avelon-theme-dark-on-primary-rgb), 0.5);
  --mat-filled-button-focus-state-layer-opacity: 0.24;
  --mat-filled-button-pressed-state-layer-opacity: 0.24;

  .mat-mdc-unelevated-button {
    &.mat-unthemed {
      --mdc-filled-button-container-color: var(--avelon-theme-dark-primary-50);
      --mdc-filled-button-label-text-color: var(--avelon-theme-dark-on-primary);
    }

    &.mat-unthemed[disabled] {
      --mdc-filled-button-disabled-container-color: rgba(78, 78, 78, 0.5);
      --mdc-filled-button-disabled-label-text-color: var(--avelon-theme-dark-text-disabled);
    }

    &.mat-primary {
      --mdc-filled-button-container-color: var(--avelon-theme-dark-primary-80);
      --mdc-filled-button-label-text-color: var(--avelon-theme-dark-on-primary);
    }

    &.mat-primary[disabled] {
      --mdc-filled-button-disabled-container-color: rgba(104, 104, 104, 0.5);
      --mdc-filled-button-disabled-label-text-color: var(--avelon-theme-dark-text-disabled);
    }
  }
}

.avelon-theme,
.avelon-theme.avelon-dark-theme {
  --mat-icon-button-hover-state-layer-opacity: 0;
  --mat-icon-button-focus-state-layer-opacity: 0;
  --mat-icon-button-pressed-state-layer-opacity: 0;
  --mdc-text-button-label-text-color: var(--on-primary);
  --mat-text-button-state-layer-color: var(--on-primary);
  --mdc-icon-button-icon-color: inherit;
  --mdc-icon-button-state-layer-size: 44px;

  button.mat-mdc-button,
  button.mat-mdc-unelevated-button:not(.download-button),
  a.mat-mdc-button,
  a.mat-flat-button:not(.download-button) {
    mat-icon {
      font-size: 3 * $base-size;
      width: 3 * $base-size;
      height: 3 * $base-size;
      vertical-align: middle;
      flex-shrink: 0;
    }

    .mdc-button__label {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  button.mat-mdc-unelevated-button.download-button,
  a.mat-mdc-unelevated-button.download-button {
    box-sizing: content-box;
    min-width: 2 * $base-width;

    mat-icon + span,
    span + mat-icon {
      margin-top: $base-padding;
    }
  }

  button.mat-mdc-unelevated-button,
  a.mat-mdc-unelevated-button {
    border-radius: 3px;
    line-height: $base-height;
    min-height: $base-height;
    min-width: 100px;
    text-decoration: none;
    text-align: center;

    &.cdk-focused.cdk-keyboard-focused .mat-mdc-button-persistent-ripple {
      background-color: transparent;
      border: 2px solid var(--text);
      opacity: 0.2;
    }
  }

  button.mat-mdc-icon-button {
    &.mat-mdc-button-base {
      font-size: inherit;
      width: $base-width;
      height: $base-height;
      padding: $base-padding;
    }

    .mat-mdc-button-touch-target {
      font-size: inherit;
      width: $base-width;
      height: $base-height;
    }
  }

  .mat-mdc-button-touch-target {
    display: none;
  }

  .mat-mdc-button-base.root-only {
    @include root-only();
  }
}
