@import "variables";

.list-item {
  color: var(--text);
  font-size: $font-size;
  position: relative;
  box-sizing: content-box;
  user-select: none;
  cursor: pointer;
  height: $base-height;
  outline: initial;

  mat-icon,
  i {
    color: var(--icon-hue);
    min-width: $icon-size;
  }

  &.selected {
    background-color: var(--selected);
    color: var(--selected-text);

    mat-icon,
    i {
      color: var(--selected-text);
    }
  }

  &:not(.selected) {
    &:not(mat-icon):hover {
      background-color: var(--hover);
      color: var(--hover-text);
    }

    mat-icon,
    i {
      color: var(--icon-hue);
    }
  }

  :focus {
    outline: none;
  }

  .item-content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 $base-padding;

    > span {
      height: $base-height;
      line-height: $base-height;
      overflow: hidden;
      padding-left: $base-padding;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1 1 auto;
    }

    .left {
      height: $base-height;
      line-height: $base-height;
      overflow: hidden;
      padding-left: $base-padding;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1 1 auto;
      min-width: $base-width;

      &.overflow-visible {
        overflow: visible;
      }
    }

    .right {
      color: var(--text-disabled);
      flex: 0 1 auto;
      padding-left: $base-padding;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &.flex-none {
        flex: none;
      }
    }

    > .mat-icon.disclosure-icon {
      padding-right: 0;

      &.level-1 {
        margin-left: 1 * (3 * $base-size);
      }

      &.level-2 {
        margin-left: 2 * (3 * $base-size);
      }

      &.level-3 {
        margin-left: 3 * (3 * $base-size);
      }
    }
  }
}

mat-option.list-item .item-content-wrapper {
  padding-left: 0;

  > span {
    &.left,
    &.right {
      padding-left: 0;
    }
  }
}
