.avelon-theme {
  --view-background: var(--avelon-theme-light-view-background); // background behind cards or on inactive areas
  --window-background: var(--avelon-theme-light-window-background); // lists, trees
  --material-background: var(--avelon-theme-light-material-background); // windows, menus, dropdowns, cards
  --material-input-background: var(--avelon-theme-light-material-input-background);
  --drag-indicator-background: var(--avelon-theme-light-drag-indicator-background);

  --primary-5: var(--avelon-theme-light-primary-5);
  --primary-10: var(--avelon-theme-light-primary-10);
  --primary-20: var(--avelon-theme-light-primary-20);
  --primary-30: var(--avelon-theme-light-primary-30);
  --primary-40: var(--avelon-theme-light-primary-40);
  --primary-50: var(--avelon-theme-light-primary-50);
  --primary-60: var(--avelon-theme-light-primary-60);
  --primary-70: var(--avelon-theme-light-primary-70);
  --primary-80: var(--avelon-theme-light-primary-80);
  --primary-90: var(--avelon-theme-light-primary-90);
  --on-primary: var(--avelon-theme-light-on-primary);
  --on-primary-90: var(--avelon-theme-light-on-primary-90);

  --tertiary-10: var(--avelon-theme-light-tertiary-10);
  --tertiary-20: var(--avelon-theme-light-tertiary-20);
  --tertiary-40: var(--avelon-theme-light-tertiary-40);
  --on-tertiary: var(--avelon-theme-light-on-tertiary);

  --on-primary-rgb: var(--avelon-theme-light-on-primary-rgb);

  --blue: var(--avelon-theme-light-blue);
  --green: var(--avelon-theme-light-green);
  --yellow: var(--avelon-theme-light-yellow);
  --orange: var(--avelon-theme-light-orange);
  --red: var(--avelon-theme-light-red);
  --on-blue: var(--avelon-theme-light-on-blue);
  --on-green: var(--avelon-theme-light-on-green);
  --on-yellow: var(--avelon-theme-light-on-yellow);
  --on-orange: var(--avelon-theme-light-on-orange);
  --on-red: var(--avelon-theme-light-on-red);

  --tint-blue: var(--avelon-theme-light-tint-blue);
  --tint-green: var(--avelon-theme-light-tint-green);
  --tint-yellow: var(--avelon-theme-light-tint-yellow);
  --tint-orange: var(--avelon-theme-light-tint-orange);
  --tint-red: var(--avelon-theme-light-tint-red);
  --on-tint-blue: var(--avelon-theme-light-on-tint-blue);
  --on-tint-green: var(--avelon-theme-light-on-tint-green);
  --on-tint-yellow: var(--avelon-theme-light-on-tint-yellow);
  --on-tint-orange: var(--avelon-theme-light-on-tint-orange);
  --on-tint-red: var(--avelon-theme-light-on-tint-red);

  --message-blue: var(--avelon-theme-light-message-blue);
  --message-green: var(--avelon-theme-light-message-green);
  --message-yellow: var(--avelon-theme-light-message-yellow);
  --message-orange: var(--avelon-theme-light-message-orange);
  --message-red: var(--avelon-theme-light-message-red);
  --on-message-blue: var(--avelon-theme-light-on-message-blue);
  --on-message-green: var(--avelon-theme-light-on-message-green);
  --on-message-yellow: var(--avelon-theme-light-on-message-yellow);
  --on-message-orange: var(--avelon-theme-light-on-message-orange);
  --on-message-red: var(--avelon-theme-light-on-message-red);

  --edit-mode: var(--orange);
  --on-edit-mode: var(--on-orange);
  --save: var(--blue);
  --on-save: var(--on-blue);

  --topbar: var(--avelon-theme-light-topbar);
  --topbar-text: var(--avelon-theme-light-topbar-text);

  --text: var(--avelon-theme-light-text);
  --text-disabled: var(--avelon-theme-light-text-disabled);
  --link: var(--avelon-theme-light-link);
  --ci-color: #ff8522;
  --icon-hue: var(--avelon-theme-light-icon-hue);
  --icon-hue-disabled: var(--avelon-theme-light-icon-hue-disabled);
  --hover: var(--avelon-theme-light-hover);
  --hover-text: var(--avelon-theme-light-hover-text);
  --selected: var(--avelon-theme-light-selected);
  --selected-text: var(--avelon-theme-light-selected-text);
  --selected-shape-fill: var(--avelon-theme-light-selected-shape-fill);
  --selected-shape-stroke: var(--avelon-theme-light-selected-shape-stroke);
  --label-text: var(--avelon-theme-light-label-text);
  --box-border: var(--avelon-theme-light-box-border);
  --box-transparency: var(--avelon-theme-light-box-transparency);
  --filter-color: var(--orange);

  --light-widget-hue: rgb(255, 255, 255);
  --light-widget-hue-semi-transparent: rgba(255, 255, 255, 0.5);
  --medium-widget-hue: #e6e6e6;
  --dark-widget-hue: #cccccc;
  --widget-overlay-even: rgba(255, 255, 255, 0.85);
  --widget-overlay-even-hover: rgb(235, 235, 235, 0.85);
  --widget-overlay-odd: rgba(255, 255, 255, 0.9);
  --widget-overlay-odd-hover: rgba(235, 235, 235, 0.9);

  --mdc-theme-text-primary-on-background: var(--avelon-theme-light-text);
  --mdc-theme-text-disabled-on-background: var(--avelon-theme-light-text-disabled);
}

.avelon-theme.avelon-dark-theme {
  --view-background: var(--avelon-theme-dark-view-background); // background behind cards or on inactive areas
  --window-background: var(--avelon-theme-dark-window-background); // lists, trees
  --material-background: var(--avelon-theme-dark-material-background); // windows, menus, dropdowns, cards
  --material-input-background: var(--avelon-theme-dark-material-input-background);
  --drag-indicator-background: var(--avelon-theme-dark-drag-indicator-background);

  --primary-5: var(--avelon-theme-dark-primary-5);
  --primary-10: var(--avelon-theme-dark-primary-10);
  --primary-20: var(--avelon-theme-dark-primary-20);
  --primary-30: var(--avelon-theme-dark-primary-30);
  --primary-40: var(--avelon-theme-dark-primary-40);
  --primary-50: var(--avelon-theme-dark-primary-50);
  --primary-60: var(--avelon-theme-dark-primary-60);
  --primary-70: var(--avelon-theme-dark-primary-70);
  --primary-80: var(--avelon-theme-dark-primary-80);
  --primary-90: var(--avelon-theme-dark-primary-90);
  --on-primary: var(--avelon-theme-dark-on-primary);
  --on-primary-90: var(--avelon-theme-dark-on-primary);

  --tertiary-10: var(--avelon-theme-dark-tertiary-10);
  --tertiary-20: var(--avelon-theme-dark-tertiary-20);
  --tertiary-40: var(--avelon-theme-dark-tertiary-40);
  --on-tertiary: var(--avelon-theme-dark-on-tertiary);

  --on-primary-rgb: var(--avelon-theme-dark-on-primary-rgb);

  --blue: var(--avelon-theme-dark-blue);
  --green: var(--avelon-theme-dark-green);
  --yellow: var(--avelon-theme-dark-yellow);
  --orange: var(--avelon-theme-dark-orange);
  --red: var(--avelon-theme-dark-red);
  --on-blue: var(--avelon-theme-dark-on-blue);
  --on-green: var(--avelon-theme-dark-on-green);
  --on-yellow: var(--avelon-theme-dark-on-yellow);
  --on-orange: var(--avelon-theme-dark-on-orange);
  --on-red: var(--avelon-theme-dark-on-red);

  --tint-blue: var(--avelon-theme-dark-tint-blue);
  --tint-green: var(--avelon-theme-dark-tint-green);
  --tint-yellow: var(--avelon-theme-dark-tint-yellow);
  --tint-orange: var(--avelon-theme-dark-tint-orange);
  --tint-red: var(--avelon-theme-dark-tint-red);
  --on-tint-blue: var(--avelon-theme-dark-on-tint-blue);
  --on-tint-green: var(--avelon-theme-dark-on-tint-green);
  --on-tint-yellow: var(--avelon-theme-dark-on-tint-yellow);
  --on-tint-orange: var(--avelon-theme-dark-on-tint-orange);
  --on-tint-red: var(--avelon-theme-dark-on-tint-red);

  --message-blue: var(--avelon-theme-dark-message-blue);
  --message-green: var(--avelon-theme-dark-message-green);
  --message-yellow: var(--avelon-theme-dark-message-yellow);
  --message-orange: var(--avelon-theme-dark-message-orange);
  --message-red: var(--avelon-theme-dark-message-red);
  --on-message-blue: var(--avelon-theme-dark-on-message-blue);
  --on-message-green: var(--avelon-theme-dark-on-message-green);
  --on-message-yellow: var(--avelon-theme-dark-on-message-yellow);
  --on-message-orange: var(--avelon-theme-dark-on-message-orange);
  --on-message-red: var(--avelon-theme-dark-on-message-red);

  --edit-mode: var(--orange);
  --on-edit-mode: var(--on-orange);
  --save: var(--blue);
  --on-save: var(--on-blue);

  --topbar: var(--avelon-theme-dark-topbar);
  --topbar-text: var(--avelon-theme-dark-topbar-text);

  --text: var(--avelon-theme-dark-text);
  --text-disabled: var(--avelon-theme-dark-text-disabled);
  --link: var(--avelon-theme-dark-link);
  --icon-hue: var(--avelon-theme-dark-icon-hue);
  --icon-hue-disabled: var(--avelon-theme-dark-icon-hue-disabled);
  --hover: var(--avelon-theme-dark-hover);
  --hover-text: var(--avelon-theme-dark-hover-text);
  --selected: var(--avelon-theme-dark-selected);
  --selected-text: var(--avelon-theme-dark-selected-text);
  --selected-shape-fill: var(--avelon-theme-dark-selected-shape-fill);
  --selected-shape-stroke: var(--avelon-theme-dark-selected-shape-stroke);
  --label-text: var(--avelon-theme-dark-label-text);
  --box-border: var(--avelon-theme-dark-box-border);
  --box-transparency: var(--avelon-theme-dark-box-transparency);
  --filter-color: var(--yellow);

  --light-widget-hue: rgb(51, 51, 51);
  --light-widget-hue-semi-transparent: rgb(51, 51, 51, 0.5);
  --medium-widget-hue: #2b2b2b;
  --dark-widget-hue: #222222;
  --widget-overlay-even: rgba(21, 21, 21, 0.85);
  --widget-overlay-even-hover: rgba(43, 43, 43, 0.85);
  --widget-overlay-odd: rgba(21, 21, 21, 0.9);
  --widget-overlay-odd-hover: rgba(43, 43, 43, 0.9);

  --mdc-theme-text-primary-on-background: var(--avelon-theme-dark-text);
  --mdc-theme-text-disabled-on-background: var(--avelon-theme-dark-text-disabled);
}
