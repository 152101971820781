@charset "UTF-8";
@font-face {
  font-family: "Material Symbols";
  font-style: normal;
  font-weight: 200;
  font-display: block;
  src:
    local("☺"),
    url("../assets/fonts/material-symbols-outlined.woff2") format("woff2");
}

.material-icons {
  font-family: "Material Symbols";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";

  &.filled {
    font-variation-settings: "FILL" 1;
  }
}
