@import "variables";

.mat-headline-2 {
  font: 400 56px/56px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: -.02em;
  margin: 0 0 8 * $base-padding 0;
}

.mat-headline-4 {
  font: 400 34px/40px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
  margin: 0 0 8 * $base-padding 0;
}

.mat-headline-6 {
  font: 500 20px/32px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
  margin: 0 0 2 * $base-padding 0;
}

.mat-subtitle-1 {
  font: 400 16px/28px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
  margin: 0 0 2 * $base-padding 0;
}

.mat-body-1 {
  font: 400 15px/24px Roboto, Helvetica Neue, sans-serif;
  letter-spacing: normal;
  margin: 0 0 16px;
}