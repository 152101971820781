@import "variables";

@mixin root-only($dark-theme: false, $hover: false) {
  background: var(--tint-red);

  @if $hover {
    &:not(:disabled):hover {
      background: var(--tint-red);

      &::before {
        background-color: var(--hover);
        content: " ";
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
}
